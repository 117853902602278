<template>
  <div class="bg-white facility-list">
    <app-modal-confirm
      ref="facility_delete_modal"
      :modal-header-title="$t('common.delete confirm title')"
      @confirmed="deleteFacility"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common.delete confirm") }}</p>
      </template>
    </app-modal-confirm>
    <div class="form-group row">
      <div class="col-12">
        <div class="form-group row" v-if="idEditing">
          <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label pl-3">{{ $t("common.id") }}</label>
          <div class="col-xl-5 col-lg-6 col-sm-6 p-t-8"> {{ entry.id }}</div>
        </div>
        <div class="card card-primary card-outline card-outline-tabs">
          <div class="card-header p-0 pt-1 border-bottom-0">
            <ul class="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active" id="customer-meals-tab" data-toggle="pill" href="#setting-tab"
                  role="tab" aria-controls="apartments-settings" aria-selected="true">
                  {{ $t("facilities.global_setting") }}
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="customer-snack-tab" data-toggle="pill" href="#detail-tab" role="tab"
                   aria-controls="apartments-api_key" aria-selected="false">
                  {{ $t("facilities.detail_setting") }}
                </a>
              </li>
            </ul>
          </div>
          <validation-observer
            ref="global_setting"
            v-slot="{ passes, valid, validated }"
          >
              <div class="card-body p-0">
                <div class="tab-content" id="tab-customer-snack">
                  <div class="tab-pane fade active show" id="setting-tab" role="tabpanel"
                       aria-labelledby="customer-meals-tab">
                    <form class="form-horizontal">
                      <div class="card-body">

                          <div class="form-group row mb-4">
                            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("facilities.serial_id") }} <span
                              class="app-require">＊</span>
                            </label>
                            <div class="col-xl-6 col-lg-8 col-sm-8">
                              <app-input
                                name="serial_id"
                                rules="required"
                                :class="['flex-fill']"
                                type="string128"
                                v-model="entry.serial_id"
                              />
                            </div>
                          </div>
                          <div class="form-group row mb-4">
                            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("facilities.name") }} <span
                              class="app-require">＊</span>
                            </label>
                            <div class="col-xl-6 col-lg-8 col-sm-8">
                              <app-input
                                name="name"
                                rules="required"
                                type="string:12"
                                :class="['flex-fill']"
                                v-model="entry.name"
                              />
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("facilities.description") }}
                              <span
                                class="app-require">＊</span>
                            </label>
                            <div class="col-xl-6 col-lg-8 col-sm-8">
                              <app-text
                                name="description"
                                rules="required"
                                type="string:50"
                                :class="['flex-fill']"
                                v-model="entry.description"
                              />
                            </div>
                          </div>

                        <validation-provider
                          name="biz_type"
                          rules="required"
                          v-slot="{ errors, invalid, validated }"
                        >
                          <div class="form-group row">
                            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label mt-2">{{
                                $t("facilities.biz_type")
                              }} <span
                                class="app-require">＊</span>
                            </label>
                            <div class="col-xl-6 col-lg-6 col-sm-8 align-self-center" style="margin-top: 10px;">
                              <app-radio v-for="(item, index) in FACILITIES_CONSTANT.biz_type"
                                         :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                                         :val="item.id" v-model="entry.biz_type"/>
                              <label v-if="invalid && validated" class="error">
                                {{ errors[0] }}
                              </label>
                            </div>
                          </div>
                        </validation-provider>

                          <validation-provider
                            name="status_disp_crowded"
                            rules="required"
                            v-slot="{ errors, invalid, validated }"
                          >
                            <div class="form-group row">
                              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label mt-2">{{
                                $t("facilities.status_disp_crowed") }} <span
                                  class="app-require">＊</span>
                              </label>
                              <div class="col-xl-6 col-lg-6 col-sm-8 align-self-center" style="margin-top: 10px;">
                                <app-radio v-for="(item, index) in FACILITIES_CONSTANT.disp_crowed"
                                           :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                                           :val="item.id" v-model="entry.status_disp_crowded"/>
                                <label v-if="invalid && validated" class="error">
                                  {{ errors[0] }}
                                </label>
                              </div>
                            </div>
                          </validation-provider>

                          <validation-provider
                            name="status_disp_guide"
                            rules="required"
                            v-slot="{ errors, invalid, validated }"
                          >
                            <div class="form-group row">
                              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label mt-2">{{ $t("facilities.status_disp_guide")
                                }} <span
                                  class="app-require">＊</span>
                              </label>
                              <div class="col-xl-6 col-lg-6 col-sm-8 align-self-center" style="margin-top: 10px;">
                                <app-radio v-for="(item, index) in FACILITIES_CONSTANT.disp_guide"
                                           :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                                           :val="item.id" v-model="entry.status_disp_guide"/>
                                <label v-if="invalid && validated" class="error">
                                  {{ errors[0] }}
                                </label>
                              </div>
                            </div>
                          </validation-provider>

                          <validation-provider
                            name="status_disp_signage"
                            rules="required"
                            v-slot="{ errors, invalid, validated }"
                          >
                            <div class="form-group row mb-4">
                              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label mt-2">{{
                                $t("facilities.status_disp_signage") }} <span
                                  class="app-require">＊</span>
                              </label>
                              <div class="col-xl-6 col-lg-6 col-sm-8 align-self-center" style="margin-top: 10px;">
                                <app-radio v-for="(item, index) in FACILITIES_CONSTANT.disp_signage"
                                           :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                                           :val="item.id" v-model="entry.status_disp_signage"/>
                                <label v-if="invalid && validated" class="error">
                                  {{ errors[0] }}
                                </label>
                              </div>

                            </div>
                          </validation-provider>

                          <div class="form-group row">
                            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("facilities.order_disp_crowded") }}
                            </label>
                            <div class="col-xl-6 col-lg-8 col-sm-8">
                              <app-input
                                name="order_diswsp_crowded"
                                :class="['flex-fill']"
                                v-model="entry.order_disp_crowded"
                                type="number-length:5"
                                :rules="VALIDATE.V_SMALL_INT"
                              />
                            </div>
                          </div>
                          <div class="form-group row pt-2">
                            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("facilities.order_disp_guide") }}
                            </label>
                            <div class="col-xl-6 col-lg-8 col-sm-8">
                              <app-input
                                name="order_disp_guide"
                                :class="['flex-fill']"
                                v-model="entry.order_disp_guide"
                                type="number-length:5"
                                :rules="VALIDATE.V_SMALL_INT"
                              />
                            </div>
                          </div>
                          <div class="form-group row pt-2">
                            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("facilities.web_url") }}
                            </label>
                            <div class="col-xl-6 col-lg-8 col-sm-8">
                              <app-input
                                name="web_url"
                                :class="['flex-fill']"
                                v-model="entry.url"
                                type="string512"
                                rules="url"
                              />
                            </div>
                          </div>
                          <div class="form-group row pt-2">
                            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("facilities.image") }}</label>
                            <div class="col-xl-6 col-lg-6 col-sm-8">
                              <app-single-file-input accept="image/jpeg, image/png" name="image_path"
                                                     :imageSrc="entry.image_path"
                                                     @input="handlerUploadImage" @updated_at="entry.data_updated_at = $event" :delete-btn="true" :id-editing="idEditing" :url-remove="ENDPOINT.FACILITY_DELETE_IMAGE(idEditing)" :allowedExtension="allowedExtension" ></app-single-file-input>
                            </div>
                          </div>
                          <validation-provider
                            name="booking_flow_type"
                            v-slot="{ errors, invalid, validated }"
                          >
                            <div class="form-group row">
                              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("facilities.booking_flow_type")}}
                              </label>
                              <div class="col-xl-6 col-lg-8 col-sm-8 align-self-center grid">
                                <app-radio v-for="(item, index) in FACILITIES_CONSTANT.booking_flow_type"
                                           :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                                           :val="item.id" v-model="entry.booking_flow_type"/>
                                <label class="notifi-resetpass m-t-20">{{ $t("facilities.label_booking_flow_type") }}</label>
                              </div>
                            </div>
                            <div class="form-group">
                              <label v-if="invalid && validated" class="error">
                                {{ errors[0] }}
                              </label>
                            </div>
                          </validation-provider>
                          <validation-provider
                            name="gateway_disp_type"
                            rules="required"
                            v-slot="{ errors, invalid, validated }"
                          >
                            <div class="form-group row">
                              <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("facilities.gateway_disp_type")
                                }} <span
                                  class="app-require">＊</span>
                              </label>
                              <div class="col-xl-9 col-lg-8 col-sm-8 align-self-center gr-icheckradio mt-2 mb-0">
                                <app-radio v-for="(item, index) in FACILITIES_CONSTANT.gateway_disp_type"
                                            :key="item.id" :label="item.name"
                                           :val="item.id" v-model="entry.gateway_disp_type" class="check-item">
                                  <div class="form-group form-group-gateway pl-1 w-100 mt-3" slot="extraField" v-if="index == 0">
                                    <div class="row mb-4">
                                      <label class="col-sm-6 col-xl-3  d-flex align-self-center">{{
                                        $t("facilities.gateway_threshold_available_max") }} <span
                                          v-if="entry.gateway_disp_type == 0" class="app-require">＊</span>
                                      </label>
                                      <div class="col-sm-6 col-xl-5 flex_35">
                                        <app-input
                                          name="gateway_threshold_available_max"
                                          :rules="entry.gateway_disp_type == 0 ? VALIDATE.V_TINY_INT_REQUIRED + `|min_max_number:${entry.gateway_threshold_crowded_max}`
                                          : ''"
                                          :disabled="entry.gateway_disp_type == 0 ? false : true"
                                          :class="['flex-fill']"
                                          v-model="entry.gateway_threshold_available_max"
                                          type="number-length:3"
                                        />
                                      </div>
                                    </div>
                                    <div class="row">
                                      <label class="col-sm-6 col-xl-3  d-flex align-self-center">{{
                                        $t("facilities.gateway_threshold_crowded_max") }} <span
                                          v-if="entry.gateway_disp_type == 0"  class="app-require">＊</span>
                                      </label>
                                      <div class="col-sm-6 col-xl-3 flex_35">
                                        <app-input
                                          name="gateway_threshold_crowded_max"
                                          :rules="entry.gateway_disp_type == 0 ? VALIDATE.V_TINY_INT_REQUIRED : ''"
                                          :disabled="entry.gateway_disp_type == 0 ? false : true"
                                          :class="['flex-fill']"
                                          v-model="entry.gateway_threshold_crowded_max"
                                          type="number-length:3"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group form-group-gateway pl-1 w-100 mt-3 mb-0" slot="extraField" v-if="index == 1">
                                    <div class="row mb-4">
                                      <label class="col-sm-6 col-xl-3  d-flex align-self-center">{{
                                        $t("facilities.gateway_available_api_id") }} <span
                                          v-if="entry.gateway_disp_type == 1"  class="app-require">＊</span>
                                      </label>
                                      <div class="col-sm-6 col-xl-3 flex_35">
                                        <app-input
                                          name="gateway_available_api_id"
                                          type="string128"
                                          :class="['flex-fill']"
                                          v-model="entry.gateway_available_api_id"
                                          :rules="entry.gateway_disp_type == 1 ? 'required' : ''"
                                          :disabled="entry.gateway_disp_type == 1 ? false : true"
                                        />
                                      </div>
                                    </div>
                                    <div class="row">
                                      <label class="col-sm-6 col-xl-3  d-flex align-self-center">{{
                                        $t("facilities.gateway_available_gateway_serial") }} <span
                                          v-if="entry.gateway_disp_type == 1"  class="app-require">＊</span>
                                      </label>
                                      <div class="col-sm-6 col-xl-3 flex_35">
                                        <app-input
                                          name="gateway_available_gateway_serial"
                                          :class="['flex-fill']"
                                          v-model="entry.gateway_available_gateway_serial"
                                          :rules="entry.gateway_disp_type == 1 ? 'required' : ''"
                                          :disabled="entry.gateway_disp_type == 1 ? false : true"
                                          type="string128"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </app-radio>
                                <label v-if="invalid && validated" class="error mt-0 mb-3">
                                  {{ errors[0] }}
                                </label>
                              </div>
                            </div>

                          </validation-provider>
                          <div class="form-group row mb-4">
                            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("facilities.gateway_unit_time")
                              }} <span
                                class="app-require">＊</span>
                            </label>
                            <div class="col-xl-6 col-lg-8 col-sm-8">
                              <app-select
                                name="gateway_unit_time"
                                rules="required"
                                :options-data="FACILITIES_CONSTANT.gateway_unit_time"
                                v-model="entry.gateway_unit_time"
                              />
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-xl-2 col-lg-3 col-sm-3 col-form-label">{{ $t("facilities.mansion_id") }} <span
                              class="app-require">＊</span>
                            </label>
                            <div class="col-xl-6 col-lg-8 col-sm-8">
                              <app-select
                                name="mansion_id"
                                rules="required"
                                :options-data="meta.apartments"
                                v-model="entry.apartment_id"
                              />
                            </div>
                          </div>
                      </div>
                    </form>
                  </div>
                  <div class="tab-pane fade" id="detail-tab" role="tabpanel"
                       aria-labelledby="customer-snack-tab">
                    <div class="card-body">
                      <validation-observer
                        ref="detail_setting"
                        v-slot="{ passes, valid, validated }"
                      >
                        <validation-provider
                          name="detail_button_status"
                          v-slot="{ errors, invalid, validated }"
                        >
                          <div class="form-group row">
                            <label class="col-sm-10 col-form-label">{{ $t("facilities.detail_button_status") }}
                            </label>
                            <div class="col-sm-10 align-self-center">
                              <app-radio v-for="(item, index) in FACILITIES_CONSTANT.detail_button_status"
                                         :isMarginLeft="index !=0 ? true : false" :key="item.id" :label="item.name"
                                         :val="item.id" v-model="entry.detail_buttons_status" />
                              <label v-if="invalid && validated" class="error">
                                {{ errors[0] }}
                              </label>
                            </div>
                          </div>
                        </validation-provider>
                        <!-- get this -->
                        <div class="form-group">
                          <div class="row p-0 justify-content-between">
                            <div class="col-xl-8 col-lg-7 col-md-7 col-sm-8">
                              <label class="col-sm-10 pl-0 col-form-label">{{ $t("facilities.detail_content") }}</label>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-3">
                              <label class="col-sm-12 pl-0 col-form-label">{{ $t("facilities.html_preview") }}</label>
                            </div>
                          </div>
                          <div class="row p-0 justify-content-between">
                            <div class="col-xl-8 col-lg-7 col-md-7 col-sm-8">
                                <app-summer-note v-if="loading" max-length="5120"
                                  @input="entry.detail_contents = $event" :val="entry.detail_contents">
                                </app-summer-note>
                            </div>
                            <div class="col-xl-3 col-lg-4 col-md-4 col-sm-3">
                              <div class="html-preview">
                                <div v-html="entry.detail_contents"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </validation-observer>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card -->
              <div class="card-footer row justify-content-end">
                <button type="button" class="btn btn-default m-r-10" :disabled="!valid && validated"  @click="handlerCancelButton">
                  {{ $t('common.cancel') }}
                </button>
                <button type="button" class="btn btn btn-danger m-r-10" :disabled="!valid && validated"  @click="handlerDeleteFacility" v-if="idEditing">
                  {{ $t('apartments.delete') }}
                </button>
                <button class="btn btn-primary m-r-10" :disabled="!valid && validated"   type="button" @click="createOrUpdate">
                  {{idEditing ?  $t('common.save') :  $t('common.create')}}
                </button>
              </div>
          </validation-observer>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
  import AppModal from "../../../components/_common/popup/AppModal";
  import AppBasicTable from "@components/_common/list/AppBasicTable";
  import AppModalCustomer from "@components/_common/popup/AppModalCustomer";
  import AppCheckbox from "../../../components/forms/AppCheckbox.vue";
  import AppSingleFileInput from "../../../components/forms/AppSingleFileInput.vue";
  import {FACILITIES_CONSTANT} from "../../../constants/facilities";
  import AppSummerNote from "../../../components/forms/AppSummernote";
  import {ROUTES} from "@constants";

  export default {
    name: "FacilityCreateOrEditPage",
    components: {
      AppSummerNote,
      AppModal,
      AppDateRangePicker,
      "app-basic-table": AppBasicTable,
      AppModalCustomer,
      "app-checkbox": AppCheckbox,
      AppSingleFileInput
    },
    data() {
      const id = this.$route.params.id
      return {
        meta: {
          apartments: [],
        },
        entry: {
          detail_contents: "",
          booking_flow_type : 1,
          biz_type : 0,
        },
        idEditing: id,
        loading : true,
        allowedExtension : ['image/jpeg', 'image/png']
      }
    },
    async created() {
      if (this.idEditing) {
        this.loading = false;
        await this.$request.get(this.ENDPOINT.FACILITY_EDIT(this.idEditing)).then(res => {
          if (res.hasErrors()) {
            if (res.status === 'error'){
              this.__noticeError(this.$t(res.data.error_msg));
            }
            this.redirectDefaultValue()
          } else {
            this.entry = res.data.data
            this.loading = true;
          }
        })
      }
    },
    mounted: async function () {
      $(".tooltip").tooltip("hide");
      this.$request.get(this.ENDPOINT.UI_HELPER_CUSTOMER_MEALS).then(res => {
        this.meta = {
          apartments: res.data.apartments,
        }
        this.lastApartment = res.data.apartments[0];
        this.meta.apartments.unshift({
          id: "",
          name: "--選択--"
        })
      })
    },
    methods: {
      async deleteFacility() {
        const res = await this.$request.delete(this.ENDPOINT.FACILITY_DELETE(this.idEditing));
        if (!res.hasErrors()) {
          this.__noticeSuccess(this.$t("common.msg delete ok"));
          await this.redirectDefaultValue();
        } else {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          this.$refs.facility_delete_modal.hide();
          if (res.status === 'error') {
            await this.redirectDefaultValue()
          }
        }
      },
      handlerDeleteFacility() {
        this.$refs.facility_delete_modal.show();
      },
      async createOrUpdate() {
        if (!await this.$refs.global_setting.validate() || !await this.$refs.detail_setting.validate()) {
          return;
        }
        let res = null;
        let msg = "";
        if (!this.idEditing) {
          res = await this.$request.post(this.ENDPOINT.FACILITY_CREATE, this.entry);
          msg = this.$t("common.msg create ok")
        } else {
          res = await this.$request.patch(this.ENDPOINT.FACILITY_EDIT(this.idEditing), this.entry);
          msg = this.$t("common.msg update ok")
        }
        if (res.hasErrors()) {
          this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
          if (res.status === 'error') {
            this.redirectDefaultValue()
          }
        } else {
          this.__noticeSuccess(msg);
          await this.redirectDefaultValue();
        }
      },
      handlerUploadImage(data) {
       this.entry.image = data
       this.entry.image_path = data
      },
      handlerCancelButton() {
        if(this.$store.state.setting.prevRouter.name === ROUTES.ADMIN.FACILITY_LIST) {
          this.__redirectBack()
        } else {
          this.redirectDefaultValue()
        }
      },
      redirectBack() {
        this.$router.go(-1);
      },
      async redirectDefaultValue() {
        await this.$router.push({
          name: this.ROUTES.ADMIN.FACILITY_LIST,
          query: {'filters.status_disp_crowded.equal': 'all', 'perPage' : '99999'}
        });
      }
    }
  }
</script>
<style>
  .facility-list .error {
    margin-bottom: 20px;
    line-height: 15px;
    heigth: 15px;
    margin-top: 0;
  }
</style>
